import { withRouter } from "react-router";
import RGL, { WidthProvider } from 'react-grid-layout';
import React from "react";
import DashboardItem from "../components/DashboardItem";
import ChartRenderer from "../components/ChartRenderer";
import { isMobile } from "react-device-detect";
import ChartRendererTable from '../components/ChartRendererTable';
import ButtonsComponent from "./Buttons";
import { useTranslation } from 'react-i18next';
import ReactGridLayout from "react-grid-layout";


const UserTnfDigestComponent = (props) => {
    const [t, i18n] = useTranslation();
    const {
        tnfTableData
    } = props;

    const deserializeItem = (i) => ({
        ...i,
        layout: JSON.parse(i.layout) || {},
        //vizState: JSON.parse(i.vizState)
    });
    const defaultLayout = (i) => ({
        x: i.layout.x || 0,
        y: i.layout.y || 0,
        w: i.layout.w || 4,
        h: i.layout.h || 8,
        minW: 4,
        minH: 8,
    });

    const defaultLayoutMobile = (i) => ({
        x: i.layout.x || 0,
        y: i.layout.y || 0,
        w: 12,
        // w: i.layout.w || 4,
        h: i.layout.h + 2 || 12,
        minW: 4,
        minH: 8,
    });

    const dashboardItem = (item) => (
        <div
            key={item.id}
            //className="model-table"
            data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}
        >
            <DashboardItem key={item.id} itemId={item.id} title={item.name}>
                <ChartRendererTable
                    apiUrl={item.apiUrl}
                    onItemChange={props.onItemChange}
                    showCount={false}
                />

                <div className="ps-3 text-end">
                    <ButtonsComponent
                        onClick={(e) => props.handleSubmitButtonClick(e)}
                        isIcon={false}
                        dissable={tnfTableData && tnfTableData.length > 0 ? false : true}
                        isPrimary={true}
                        btn_class="me-3"
                        btnText={t("header.websiteDigestSaveButton")}
                    />
                </div>
            </DashboardItem>
        </div>
    );

    return <>
        {props.userTnfDigestData && props.userTnfDigestData.length > 0 &&
        <div>
             {props.userTnfDigestData.map(deserializeItem).map(dashboardItem)}
        </div>
            // <ReactGridLayout cols={12} rowHeight={30} isDraggable={false} isResizable={false}>
            //     {props.userTnfDigestData.map(deserializeItem).map(dashboardItem)}
            // </ReactGridLayout>
        }</>;
}
export default withRouter(UserTnfDigestComponent);