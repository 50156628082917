import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown, Button, Image } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChevronDown } from '@fortawesome/free-solid-svg-icons';
//import iconSprite from "../assets/Icon/icon-sprite.svg";
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import { NULL_VALUE_MESSAGE_DROPDOWN, TYPE_ICON, dropdownWithDefaultValue } from "../AppConfig";
import { checkValueExixtsInObject, convertTranslatinString } from "../utils/common";
import { useTranslation } from "react-i18next";


const DropdownWithIconsComponent = ({
    dropdownData,
    arrayValue,
    label,
    showLabel,
    onChange,
    iconName,
    selectedDropdown,
    showIconInDropdownItem,
    secondaryIcon,
    showSecondaryIcon,
    primaryIcon,
    primaryIconType,
    secondaryIconType,
    isDropdownDisabled,
    primaryIconCustom,
    isPrimaryImage,
    customClassName,
    showButton,
    ButtonText
}) => {

    const [t, i18n] = useTranslation();

    useEffect(() => {
        if (dropdownData && dropdownData.length > 0) {
            // if (selectedDropdown && (Object.keys(selectedDropdown).length === 2 && checkValueExixtsInObject(selectedDropdown, selectedDropdown["WebsiteList.WebsiteName"]))  || Object.keys(selectedDropdown).length === 0) {
            //  if (selectedDropdown && Object.keys(selectedDropdown).length === 0) {
            if (selectedDropdown && Object.keys(selectedDropdown).length === 0) {

                const shouldShowDefaultValue = dropdownWithDefaultValue.some(data => data === arrayValue);
                // if (arrayValue === "WebsiteList.WebsiteName" || arrayValue === "WebsiteProfiles.DisplayName") {
                if (shouldShowDefaultValue) {
                    const dta = dropdownData[0];
                    onChange && onChange(dta);
                }
            }
        }
        else {
        }
    }, []);
    // }, [dropdownData]);

    const handleDropdownChange = (data) => {
        onChange && onChange(data);

    }

    const renderDropdownValues = () => {
        if (Array.isArray(dropdownData) && dropdownData.length > 0) {
            return dropdownData.map(data => {
                return (
                    <Dropdown.Item as="span" className='w-100' onClick={() => handleDropdownChange(data)}>
                        {
                            showIconInDropdownItem &&
                            <FontAwesomeIcon icon={data[iconName]} className="me-2" />
                        }
                        {data.compareOn > -1 ? t("header.Date_Range_"+convertTranslatinString(data[arrayValue])) :  data[arrayValue]}
                    </Dropdown.Item>
                );
            });
        }
        else {
            return <option>{t("header.Dropdown_Default_"+NULL_VALUE_MESSAGE_DROPDOWN)} </option>
        }
    }

    return (
        <Form>
            <Form.Group>
                {
                    showLabel &&
                    <Form.Label>{label}</Form.Label>
                }
                <Dropdown className={customClassName}  >
                    <Dropdown.Toggle as={Button} variant="secoundary" size="" className={isDropdownDisabled ? "is-disabled" : ""}>
                        {
                            isPrimaryImage &&
                            (primaryIconType === TYPE_ICON ?
                                (primaryIconCustom ?

                                    <svg className="me-2 dropdown-primary-icon">
                                        <use href={iconSprite + `#${primaryIcon}`} />
                                    </svg>
                                    :
                                    <FontAwesomeIcon
                                        icon={primaryIcon ? primaryIcon : faPlus} className="me-2" />
                                )

                                :
                                <Image src={primaryIcon ? primaryIcon : ""} className="me-2" />
                            )

                        }
                        {showButton &&
                            <Button className="p-0 border-0 btn-secondary account-dropdown">{ButtonText}</Button>
                        }

                        {!showButton &&
                            <span className="me-2">{(selectedDropdown && Object.keys(selectedDropdown).length > 0) && selectedDropdown.compareOn > -1 ? t("header.Date_Range_"+convertTranslatinString(selectedDropdown[arrayValue])) : (selectedDropdown && Object.keys(selectedDropdown).length > 0) ?  selectedDropdown[arrayValue] : t("header.Dropdown_Default_"+NULL_VALUE_MESSAGE_DROPDOWN)} </span>
                        }
                        {
                            //  <Image src={DropdownIcon} className="dropdown-toggler" />
                            <FontAwesomeIcon
                                icon={faChevronDown} className="dropdown-toggler" />
                            // <svg style={{ height: "12px", width: "15px" }}>
                            //     <use href={iconSprite + "#chevron-down"} />
                            // </svg>
                        }
                        {/* {
                            showSecondaryIcon &&
                                secondaryIconType === TYPE_ICON ?
                                <FontAwesomeIcon
                                    icon={secondaryIcon ? secondaryIcon : faPlus} className="me-2" />
                                :
                                <Image src={secondaryIcon ? secondaryIcon : ""} className="me-2" />
                        } */}
                    </Dropdown.Toggle>
                    {
                        !isDropdownDisabled &&
                        <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left">
                            {renderDropdownValues()}
                        </Dropdown.Menu>
                    }
                </Dropdown>
            </Form.Group>
        </Form>

    );
};

DropdownWithIconsComponent.propTypes = {
    dropdownData: PropTypes.array,
    onChange: PropTypes.func,
    arrayValue: PropTypes.string,
    label: PropTypes.string,
    showLabel: PropTypes.bool,
    iconName: PropTypes.string,
    selectedDropdown: PropTypes.object,
    showIconInDropdownItem: PropTypes.bool,
    showSecondaryIcon: PropTypes.bool,
    primaryIcon: PropTypes.string,
    secondaryIcon: PropTypes.string,
    primaryIconType: PropTypes.string,
    secondaryIconType: PropTypes.string,
    isDropdownDisabled: PropTypes.bool,
    primaryIconCustom: PropTypes.bool,
    isPrimaryImage: PropTypes.bool,
    customClassName: PropTypes.string,
    showButton: PropTypes.bool,
    ButtonText: PropTypes.string


};
DropdownWithIconsComponent.defaultProps = {
    dropdownData: [],
    onChange: null,
    arrayValue: "",
    label: "",
    showLabel: false,
    iconName: "",
    selectedDropdown: {},
    showIconInDropdownItem: false,
    showSecondaryIcon: false,
    primaryIcon: "",
    secondaryIcon: "",
    primaryIconType: "",
    secondaryIconType: "",
    isDropdownDisabled: false,
    primaryIconCustom: false,
    isPrimaryImage: false,
    customClassName: "",
    showButton: false,
    ButtonText: ""

}

export default DropdownWithIconsComponent;
