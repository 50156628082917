import axios from 'axios';
import React, { Component, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import { isEmpty } from '../AppConfig';
import AppContext from '../components/AppContext';
import { getMenusAndSubMenuDetails } from '../utils/common';
import { menuItemsDetais } from '../utils/sidebar';
import { header_languages } from "../AppConfig";
import { encryptData, decryptData } from '../utils/crypto';

const ClientPreview = (props) => {
    
    const contextData = useContext(AppContext);
    const [ipAddress, setIpAddress] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [t, i18n] = useTranslation();
    const [isLanguageChanged, setIsLanguageChanged] = useState(false);
    const [captcha, setCaptcha] = useState("");

    const getMenu = () => {

        //if (email && password) {
        request(baseURL + `/UserV2/GetMainMenuByClientUser?userId=${contextData.userId}&companyid=${contextData.companyId}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            }
        )
            .then(response => {
                if (response && response.resultData.menuDetails) {
                    let menuDta = getMenusAndSubMenuDetails(response.resultData.menuDetails);
                    contextData.setMenu(menuDta);

                    if (Array.isArray(menuDta) && menuDta.length > 0) {
                        let defaultMenuData = menuDta.filter(dta => dta.isActive === true)[0];

                        if (Array.isArray(defaultMenuData.subMenu) && defaultMenuData.subMenu.length > 1) {
                            let defaultSubMenuData = defaultMenuData.subMenu.filter(dta => dta.isActive === true)[0];
                            const subMenuDetails = menuItemsDetais.find(dta => dta.title === defaultSubMenuData.title);
                            if (subMenuDetails && Object.keys(subMenuDetails).length > 0)
                                props.history.push(`${subMenuDetails.link}`);


                        }
                        else if (defaultMenuData) {
                            const menuDta = menuItemsDetais.find(dta => dta.title === defaultMenuData.title);

                            if (menuDta && Object.keys(menuDta).length > 0)
                                props.history.push(`${menuDta.link}`);
                        }
                    }
                }
                else {

                }

            })
            .catch(error => {
                console.log("login error", error);
            });
        //}
    }

    const getPublicIP = async () => {
        const IPresponse = await axios.get('https://reallyfreegeoip.org/json/');
        if (IPresponse && IPresponse.data && IPresponse.data.ip) {
            setIpAddress(IPresponse.data.ip);
            
        }
    }

    const getSelectedLanguage = () => {
        let language;
        if (i18n.language) {
            const selectedLanguagData = header_languages.find(dta => dta.id === i18n.language);
            if (selectedLanguagData && Object.keys(selectedLanguagData).length > 0) {
                language = selectedLanguagData;
                setSelectedLanguage(selectedLanguagData);
                setIsLanguageChanged(true);
            }
        }
        else {
            language = header_languages[0];
            if (contextData.languagePreference) {
                const selectedLanguagData = header_languages.find(dta => dta.id === contextData.languagePreference);
                if (selectedLanguagData && Object.keys(selectedLanguagData).length > 0)
                    language = selectedLanguagData;
                //setSelectedLanguage(selectedLanguagData);
            }
        }
        setSelectedLanguage(language);
        // i18n.changeLanguage(language.id);
    };

    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const generateString = (length) => {
        let result = '';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        setCaptcha(result);
    }

    useEffect(() => {
        if (contextData.userId > 0 && contextData.companyId > 0) {
            //if (!showMeOTPVerification && !showMeMFAVerification) 
            {
                getMenu();
            }
        }
        getPublicIP();
        getSelectedLanguage();
        generateString(6);
    }, [contextData.userId, contextData.companyId])

    useEffect(() => {
        let obj = {
            "token": props?.match?.params?.token,
            "userId": 0,
            "companyId": 0,
            "isValidate": false
        }
        request(baseURL + `/Common/GetUserDetilsUsingJWT`,
            METHODS.POST,
            obj,
            {

            }
        )
            .then((response) => {
                const { userToken, userId, languagePreference, firstName, lastName, email, selectedCompanyID } = response.result;
                const companies = response.result.resultData;
                let companyId = selectedCompanyID;
                let userName = (!isEmpty(firstName) && !isEmpty(lastName)) ? firstName.concat(lastName) : "";
                let langPref;
                langPref = languagePreference;

                localStorage.setItem("token", encryptData(userToken, contextData.cryptoKey));
                localStorage.setItem("userId", encryptData(userId, contextData.cryptoKey));
                localStorage.setItem("firstName", encryptData(firstName, contextData.cryptoKey));
                localStorage.setItem("lastName", encryptData(lastName, contextData.cryptoKey));
                localStorage.setItem("userName", encryptData(userName, contextData.cryptoKey));
                localStorage.setItem("companies", JSON.stringify(companies));
                localStorage.setItem("email", encryptData(email, contextData.cryptoKey));
                localStorage.setItem("companyId", encryptData(companyId, contextData.cryptoKey));
                localStorage.setItem("langPref", langPref);
                localStorage.setItem("userlangPref", langPref);
                localStorage.setItem("selectedCompanyID", selectedCompanyID);

                contextData.changeFirstName(firstName);
                contextData.changeLastName(lastName);
                contextData.changeUserName(userName);
                contextData.changeEmail(email);
                contextData.changeUserToken(userToken);
                contextData.changeUerId(userId);
                contextData.changeLanguagePref(langPref);
                contextData.changeCompanyId(companyId);
                contextData.setCompanies(companies);

                document.cookie = "currentUser=1"

                //this.props.history.push("/");
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])
    
    return (
        <>{t("header.HealthCheckerTabSpellingAndContentLoading")}</>
    )
}

export default ClientPreview;